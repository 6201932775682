<template>
  <base-layout class="test-view">
    <!-- <van-divider>通知栏</van-divider> -->
    <van-notice-bar left-icon="bell" background="#FBF6F0" text="请选择被保人目前或过往患有的疾病" />

    <div class="test-view--box">
      <van-divider>按钮</van-divider>
      <van-button type="primary" block>立即投保</van-button>
      <van-button type="primary" round block>去支付</van-button>
      <div class="flex-1">
        <van-button plain type="primary" style="margin-right: 3.5px">重新评估</van-button>
        <van-button type="primary" style="margin-left: 3.5px">符合，立即投保</van-button>
      </div>

      <van-divider>通知</van-divider>
      <van-button type="primary" block @click="notifyWarning">警告通知</van-button>
      <van-button type="primary" block @click="notifyDanger">危险通知</van-button>

      <van-divider>弹窗</van-divider>
      <van-button type="primary" block @click="alert">提示弹窗</van-button>
      <van-button type="primary" block @click="confirm">确认弹窗</van-button>

      <van-divider>动作面板</van-divider>
      <van-button type="primary" block @click="actionSheetShow = true">动作面板</van-button>

      <van-divider>弹窗</van-divider>
      <van-button type="primary" block @click="overlayShow = true">显示弹窗</van-button>

      <div id="nav">
        <router-link to="/orders">我的订单</router-link>
        <router-link to="/wallet">我的钱包</router-link>
        <router-link to="/text-commission-rules">佣金规则</router-link>
        <van-button type="primary" block color="#00B955" @click="toMpHomePage">返回首页</van-button>
        <van-button type="success" @click="generateIdcard">生成随机身份证号码</van-button>
        <div>{{ idCard }}</div>

        <div>
          <van-checkbox class="agreementCheck" icon-size="12" v-model="checked" shape="square" />
          我已阅读并同意签署：
          <a href="https://files.htprudent.com/G2/M00/E3/E2/CiICX2BiwpyAeJclAAPYGK-0lPE029.pdf"
            >《深圳市红彤诚新保险经纪有限公司入司资料（代理合同、诚信展业、非法集资、行为规范等）》</a
          >
          <a href="https://files.htprudent.com/G2/M00/E3/E5/CiIDVmBiwyCAVxoEAAD7OsLmkeY292.pdf">《兼职情况说明书》</a>
          <a href="https://files.htprudent.com/G1/M00/E4/AF/CiICH2BiwwCAUgyAAAMJXfb68Dk925.pdf">《人员品质管理办法》</a>

          <a href="javascript:;" @click="toMpPreviewPdfPage('/file/CiICX2BiwpyAeJclAAPYGK-0lPE029.pdf')">《xxxxxxx》</a>

          <router-link :to="{ path: '/preview-pdf', query: { url: '/file/x3HYswXwM.pdf' } }"
            >《深圳市红彤诚新保险经纪有限公司入司资料（代理合同、诚信展业、非法集资、行为规范等）》</router-link
          >
          <router-link :to="{ path: '/preview-pdf', query: { url: '/file/0HVDeBaak.pdf' } }"
            >《兼职情况说明书》</router-link
          >
          <router-link :to="{ path: '/preview-pdf', query: { url: '/file/Q5YU5-QqC.pdf' } }"
            >《人员品质管理办法》</router-link
          >
        </div>
      </div>
    </div>

    <template #fixed>
      <van-button type="primary" block>下一步</van-button>

      <!-- 定位元素 -->
      <van-action-sheet v-model:show="actionSheetShow" :actions="actions" cancel-text="取消" close-on-click-action />

      <van-overlay :show="overlayShow" @click="overlayShow = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <h3 class="title">理赔流程</h3>
            <van-icon class="close" name="cross" @click="overlayShow = false" />
          </div>
        </div>
      </van-overlay>
    </template>
  </base-layout>
</template>

<script>
import { generateIdcard } from "idcard";
import { toMpHomePage, toMpPreviewPdfPage } from "@/utils";
import { Notify, Dialog } from "@/utils/ui";

export default {
  components: {},

  data() {
    return {
      actionSheetShow: false,
      overlayShow: false,
      actions: [
        // { name: "选项一" }, { name: "选项二" }, { name: "选项三" },
        { name: "呼叫 95511-7" },
        { name: "呼叫", subname: "95511-7", className: "van-action-sheet__item--diy" }
      ],
      idCard: ""
    };
  },

  created() {},

  methods: {
    toMpHomePage,
    toMpPreviewPdfPage,
    generateIdcard() {
      // 返回随机身份证号码
      this.idCard = generateIdcard();
    },
    notifyWarning() {
      // 警告通知
      Notify({ type: "warning", message: "最多选择6个疾病" });
    },
    notifyDanger() {
      // 危险通知
      Notify({ type: "danger", message: "年龄不在28天到60周岁之前，不能投保" });
    },

    alert() {
      Dialog.alert({
        title: "标题",
        message: "弹窗内容"
      }).then(() => {
        // on close
      });
    },
    confirm() {
      Dialog.confirm({
        // title: "标题",
        message: "您投保的e生保2020(月交)已到第二期的续费日期，建议您立即续费哦！",
        messageAlign: "left",
        cancelButtonText: "狠心拒绝",
        confirmButtonText: "立即续费"
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>

<style lang="less">
.van-action-sheet .van-action-sheet__item--diy {
  display: flex;
  justify-content: center;
  align-items: center;

  // .van-action-sheet__name {
  //   font-size: 16px;
  //   color: #191919;
  // }

  .van-action-sheet__subname {
    margin: 0 0 0 6px;
    font-size: 16px;
    color: #00b955;
  }
}
</style>

<style lang="less" scoped>
.flex-1 {
  display: flex;
  & > * {
    flex: 1;
  }
}

.test-view--box {
  padding: 10px;

  > * {
    margin-bottom: 10px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .block {
    position: relative;
    width: 323px;
    height: 368px;
    background-color: #fff;
    background: #ffffff;
    border-radius: 10px;

    .title {
      margin: 0;
      padding: 16px 0;
      font-size: 18px;
      color: #353535;
      letter-spacing: 0.38px;
      text-align: center;
    }

    i.close {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 20px;
      color: #979797;
    }
  }
}

#nav {
  display: flex;
  flex-direction: column;
  padding: 30px;
  /* prettier-ignore */
  // height: 45PX;

  font-size: 20px;
}
</style>
